<template>
  <!-- Date picker will not display saved dates on mobile device if content is rendered before data is retrieved. -->
  <section v-if="!isLoading">
    <b-card :title="isNewSeason ? 'New Season' : 'Edit Season'">
      <validation-form
        :submit-buttons="{ loading: form.isLoading }"
        @validated="isNewSeason ? create() : update()"
        @cancel="goBack"
      >
        <b-row>
          <!-- Name -->
          <b-col sm="6">
            <form-validation-field
              v-model="form.name"
              vid="name"
              name="name"
              rules="required"
              label="Name*"
              placeholder="Name"
              aria-autocomplete="none"
              autofocus
            />
          </b-col>
        </b-row>

        <b-row>
          <!-- Start Date -->
          <b-col
            sm="4"
            lg="3"
          >
            <form-validation-date-picker
              ref="startDate"
              v-model="form.start_date"
              name="start_date"
              rules="required"
              label="Start Date*"
              placeholder="Select start date"
              aria-autocomplete="none"
              :config="{
                altInput: true,
              }"
            />
          </b-col>

          <!-- End Date -->
          <b-col
            sm="4"
            lg="3"
          >
            <form-validation-date-picker
              ref="endDate"
              v-model="form.end_date"
              name="end_date"
              rules="required"
              label="End Date*"
              placeholder="Select end date"
              aria-autocomplete="none"
              :config="{
                altInput: true,
              }"
            />
          </b-col>
        </b-row>
      </validation-form>
    </b-card>

    <card-delete
      v-if="!isNewSeason"
      item-name="season"
      @delete="$store.commit('app/deleteModal/UPDATE_DELETE_MODAL',{
        title: 'Delete Season',
        itemName: form.name,
        endpoint: `/seasons/${form.id}`,
        afterDelete: goBack
      })"
    />

  </section>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import FormValidationDatePicker from '@/components/forms/validation/FormValidationDatePicker.vue'
import CardDelete from '@/components/cards/CardDelete.vue'

import Form from '@/forms/Form'
import axios from '@/libs/axios'
import useFlash from '@/composables/useFlash'
import { mapState } from 'vuex'
import { userUsedMasterPassword } from '@/auth/utils'

const { flashError } = useFlash()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    ValidationForm,
    FormValidationField,
    FormValidationDatePicker,
    CardDelete,
  },
  data() {
    return {
      isLoading: true, // This is needed for saved dates to show up mobile device (issue with flatPickr not updating).
      form: new Form({
        name: '',
        start_date: null,
        end_date: null,
        playing_periods: null,
        regulation_length: null,
        overtime_length: null,
        event_id: '',
      }),
    }
  },
  computed: {
    isNewSeason() {
      return !this.$route.params.season
    },
    ...mapState('event/seasons', ['seasons']),
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.season) {
      if (!userUsedMasterPassword()) {
        next({ name: 'event.seasons' })
      }

      next(vm => {
        vm.isLoading = false
      })
      return
    }

    axios.get(`/seasons/${to.params.season}`)
      .then(response => {
        const { data } = response.data

        next(vm => {
          Object.assign(vm.form, data)
          vm.isLoading = false
        })
      })
      .catch(error => {
        flashError(error)
      })
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'event.seasons' })
    },
    create() {
      this.form.post(`/events/${this.$route.params.event}/seasons`)
        .then(response => {
          const { data } = response

          this.seasons.push(data)
          this.goBack()
        })
    },
    update() {
      this.form.put(`/seasons/${this.$route.params.season}`)
        .then(response => {
          const { data } = response

          Object.assign(this.form, data)

          this.seasons.splice(this.seasons.findIndex(s => s.id === data.id), 1, data)
          this.goBack()
        })
    },
  },
}
</script>
